/*
 * @Author: shao
 * @Date: 2023-08-23 16:14:09
 * @LastEditors: [you name]
 * @LastEditTime: 2023-08-31 14:23:50
 * @Description: 
 */

import _axios from 'axios';
import * as history from 'history';
import { Toast } from 'antd-mobile';

import { request, response } from './interceptors';

const axios = _axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

axios.interceptors.request.use(request);
axios.interceptors.response.use(response, err => {
 
  console.log("拦截>>>>",err.response)
  if (err.response) {
    if (err.response.data.code === 200000||err.response.data.code === 200001) {
      history.createBrowserHistory().push('/login');
      window.location.reload();
    }
  }
});

export const post = (url:any, params = {}, needUserId = true) => {
  return new Promise((resolve, errs) => {
      console.log(">>>>",url)

      axios.post(url, params)
          .then(res => {
              console.log(">>>>>",res)
              resolve(res)
          })
          .catch(err => {
              console.log(">>>>>",err)
          });
  })
}

export const get = (url:any, params = {}) => {
  return new Promise(resolve => {
    axios.get(url, {params: params})
          .then(res => {
              resolve(res)
          })
          .catch(err => {
            console.log(">>>>>",err)
          })
     
  })
}

export default axios;
