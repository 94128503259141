/*
 * @Author: shao
 * @Date: 2023-08-24 09:56:47
 * @LastEditors: [you name]
 * @LastEditTime: 2023-08-28 16:14:39
 * @Description: 
 */
import {post, get} from '../../axios/index';

export function sendSms(data){
   return  post('/api/sms/send', data);
}

export function login(data) {
	return post('/api/user/login', data);
}

export function uploadFileApi(data) {
	return post('/api/aliyunOss/upload', data);
}
export function getSignUp(){
	return get('/api/signUp/get');
}
export function signStepOne(data){
	return post('/api/signUp/stepOne', data);
}
export function getProvince(data) {
	return get('/api/region/province', data);
}

export function getEduType(data) {
	return get('/api/region/edu', data);
}

export function getMajor(data) {
	return get('/api/region/major', data);
}

export function signStepTwo(data) {
	return post('/api/signUp/stepTwo', data);
}



