/*
 * @Author: shao
 * @Date: 2023-08-23 16:24:04
 * @LastEditors: [you name]
 * @LastEditTime: 2023-08-30 16:27:38
 * @Description: 
 */
import React, { useState, useEffect, useCallback } from 'react';
import "./index.scss";
import { Form, Input, Button, Toast, SafeArea, NavBar, Picker, Popup } from 'antd-mobile';
import { useHistory, Link } from 'react-router-dom';
import Logo from '@/images/login-mobile-logo.png'
import OneStepIcon from '@/images/index-step-one-sel.png'
import TwoStepIcon from '@/images/index-step-two-block.png'
import ThreeStepIcon from '@/images/index-step-three-block.png'
import OneStepFlagIcon from '@/images/index-step-one-flag.png'
import ArrowIcon from '@/images/index-arrow-down.png'
import MajorIcon from '@/images/index-major-list-bg.png'
import { getSignUp, signStepOne, getProvince,getEduType } from '../../api/index/index';
const Index = () => {
   const history = useHistory();
   const [userName, setUserName] = useState()
   const [placeType, setPlaceType] = useState()
   const [place, setPlace] = useState()
   const [phone, setPhone] = useState()
   const [eduType, setEduType] = useState()
   const [visible, setVisible] = useState(false)
   const [visible6, setVisible6] = useState(false)
   const [value, setValue] = useState()
   const [provinceColums, setProvinceColums] = useState([])
   const [eduTypeColums, setEduTypeColums] = useState([])
   const [provinceList, setProvinceList] = useState([])
   const [dialogType, setDialogType] = useState()

   const [signInfo,setSignInfo]=useState()
  
   const placeTypeColums =
      [[{ label: '户籍地', value: '户籍地' },
      {
         label: '居住地', value: '居住地'
      }]]

   const isEnableClick=()=>{
      return 
   }


   const back = () => {
      localStorage.clear()
      history.push("/login")
   }

   const clickSelect = () => {
      setVisible(true)
      setDialogType(1)
   }
   const clickSelect1 = () => {
      setVisible(true)
      setDialogType(2)
   }
   const clickSelect2 = () => {
      if(!place){
         Toast.show("请选择地区")
         return
      }
      setVisible(true)
      setDialogType(3)
   }
   const showPopup = () => {
      setVisible6(true)
   }
   useEffect(() => {
      console.log(">>>>",place)
         
      if(place){
         let param={
            province:place
         }
         getEduType(param).then(res=>{
            if (res) {
               let arr = res.map((item, index) => {
                  return {
                     label: item,
                     value: item
                  }
               })
               let colums = []
               colums.push(arr)
               setEduTypeColums(colums)
            }
         })
      }
   },[place])

   useEffect(() => {
      getSignUp().then(res => {
            if(res){
               setSignInfo(res)
               setUserName(res.realname)
               setPlaceType(res.areaType)
               setPlace(res.province)
               setPhone(res.contacts)
               setEduType(res.type)

               let  isAutoJump=  localStorage.getItem("isAutoJump")
               if(!isAutoJump){
                  if(res.progress==1){
                     localStorage.setItem("province",res.province)
                     localStorage.setItem("eduType",res.type)
                     history.push("/fill")
                  }else if(res.progress==2){
                     // 待支付 
                     history.push("/pay")
                  }else if(res.progress==3){
                     history.push("/success")
                  }
               }
               localStorage.setItem("isAutoJump",false)
            }
      })

      getProvince().then(res => {
         if (res) {
            setProvinceList(res)
            let arr = res.map((item, index) => {
               return {
                  label: item,
                  value: item
               }
            })
            let colums = []
            colums.push(arr)
            setProvinceColums(colums)
         }
      })
   }, [])

   const onUserNameChange = useCallback((value) => {
      //  let target = e.target || e.currentTarget;
      //  console.log(target.value)
       setUserName(value)
   })
   const onPhoneChange = useCallback((value) => {
      //  let target = e.target || e.currentTarget;
      //  console.log(target.value)
       setPhone(value)
   })
   const nextStep = () => {

      let param = {
         realname: userName,
         areaType: placeType,
         province: place,
         type: eduType,
         contacts: phone
      }
      if(!userName){
         Toast.show("请填写姓名")
         return
      } if(!placeType){
         Toast.show("请选择所在地类型")
         return
      } 
      if(!place){
         Toast.show("请选择地区")
         return
      }
      if(!eduType){
         Toast.show("请选择报考学历类型")
         return
      }
       if(!phone){
         Toast.show("请填写联系电话")
         return
      }
      signStepOne(param).then(res => {
         if (res) {
            localStorage.setItem("eduType",eduType)
            localStorage.setItem('province',place)
            history.push('/fill');
         }
      })

   }
   return <div className="home-container" >


      <NavBar onBack={back} >学员须知</NavBar>
      <img src={Logo} className="logo"></img>
      <div className="row">
         <img src={OneStepIcon} ></img>
         <img src={TwoStepIcon}></img>
         <img src={ThreeStepIcon} ></img>
      </div>

      <div className="form-box">

         <div className="form-body">
            <div className='row-one'>
               <img src={OneStepFlagIcon}></img>
               <div className="tip">请认真填写以下信息</div>
            </div>

            <div className="item">
               <div className="label">姓名：</div>
               <div className="form-item">
                  <Input value={userName} name="userName" placeholder="请填写您的姓名" maxLength={15} autoComplete={false} onChange={onUserNameChange} />
               </div>

               <div className="label">所在地：</div>

               <div className="row-form">
                  <div className="form-item pwd" onClick={clickSelect}>
                     <Input value={placeType} name="placeType" placeholder="请选择所在地类型" readOnly={true} />
                     <img className="eye_icon" src={ArrowIcon}></img>

                  </div>

                  <div className="form-item place" onClick={clickSelect1}>
                     <Input className="input" value={place} name="place" placeholder="请选择地区" readOnly={true} onChange={onUserNameChange} />
                     <img className="eye_icon" src={ArrowIcon}></img>

                  </div>
               </div>


               <div className="major-tip">
                  所在地的选择会影响您可选择的专业范围，可点击
               </div>
               <div className="see-major" onClick={showPopup}>
                  查看<span>专业类表详情</span>
               </div>

               <div className="label">报考类型：</div>
               <div className="form-item pwd" onClick={clickSelect2}>
                  <Input value={eduType} name="eduType" placeholder="请选择报考学历类型" readOnly={true} />
                  <img className="eye_icon" src={ArrowIcon}></img>

               </div>

               <div className="label">联系电话：</div>
               <div className="form-item ">
                  <Input value={phone} name="phone" placeholder="请填写您的手机号" maxLength={11} onChange={onPhoneChange} />
               </div>

               <div>
                  <Button className={phone && eduType && place && placeType && userName ? "btn on" : "btn"}  onClick={nextStep}>下一步</Button>
               </div>

            </div>

         </div>

      </div>



      <Picker
         columns={dialogType == 1 ? placeTypeColums : dialogType == 2 ? provinceColums : eduTypeColums}
         visible={visible}
         onClose={() => {
            setVisible(false)
         }}
         value={value}
         onConfirm={v => {

           
            if (dialogType == 1) {
               setPlaceType(v[0])
            } else if (dialogType == 2) {
               setPlace(v[0])
               setEduType('')
            } else if (dialogType == 3) {
               setEduType(v[0])
            }

         }}
      />

      <Popup
         visible={visible6}
         onMaskClick={() => {
            setVisible6(false)
         }}
      >
         <div
            style={{ height: '600px', overflowY: 'scroll', padding: '20px' }}
         >
            <img src={MajorIcon}></img>
         </div>
      </Popup>
   </div>
}

export default Index