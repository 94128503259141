/*
 * @Author: shao
 * @Date: 2023-08-23 16:24:04
 * @LastEditors: [you name]
 * @LastEditTime: 2023-08-30 14:41:28
 * @Description: 
 */
import React, { useState, useEffect, useCallback } from 'react';
import "./index.scss";
import { Form, Input, Button, Toast, SafeArea, NavBar, Picker, Popup, ImageUploader } from 'antd-mobile';
import { useHistory, Link } from 'react-router-dom';
import Logo from '@/images/login-mobile-logo.png'
import OneStepIcon from '@/images/fill-step-one.png'
import TwoStepIcon from '@/images/fill-step-two.png'
import ThreeStepIcon from '@/images/index-step-three-block.png'
import OneStepFlagIcon from '@/images/fill-step-flag.png'
import ArrowIcon from '@/images/index-arrow-down.png'
import MajorIcon from '@/images/index-major-list-bg.png'
import CardBack from '@/images/fill-card-back.png'
import CardFont from '@/images/fill-card-font.png'
import CardBackUpload from '@/images/fill-card-back-flag.png'
import CardFontUpload from '@/images/fill-card-font-flag.png'
import CardIdUpload from '@/images/fill-id-flag-upload.png'
import CardIdUploadFlag from '@/images/fill-id-flag.png'

import PersonUpload from '@/images/fill-person-photo-upload.png'
import PersonUploadFlag from '@/images/fill-person-photo-flag.png'
import EduCertUpload from '@/images/fill-edu-cert-upload.png'
import EduCertUploadFlag from '@/images/fill-edu-cert.png'

import { signStepTwo, getMajor, getSignUp } from '../../api/index/index';

import { mockUpload } from './upload'

const Index = () => {
   const history = useHistory();
   const [major, setMajor] = useState()
   const [visible, setVisible] = useState(false)

   const [visible6, setVisible6] = useState(false)
   const [value, setValue] = useState()
   const [fileList, setFileList] = useState([])
   const [cardImageList, setCardImageList] = useState([])
   const [handCardImageList, setHandCardImageList] = useState([])
   const [personImageList, setPersonImageList] = useState([])
   const [eduImageList, setEduImageList] = useState([])

   const [majorColums, setMajorColums] = useState([])

   const back = () => {
     localStorage.setItem("isAutoJump",true)
     history.push("/index")
   }

   useEffect(() => {

      getSignUp().then(res => {
         if(res){
            setMajor(res.major)
            setFileList(res.cardImageList.filter(item=> item.category==1).map((item,index)=>{return {url: item.path}}))
            setCardImageList(res.cardImageList.filter(item=> item.category==0).map((item,index)=> {return{url: item.path}}))
            setHandCardImageList(res.handCardImageList.map((item,index)=> {return{url: item.path}}))
            setPersonImageList(res.personImageList.map((item,index)=> {return{url: item.path}}))
            setEduImageList(res.eduImageList.map((item,index)=> {return{url: item.path}}))
            
         }
     })
      let param = {
         province: localStorage.getItem('province'),
         edu: localStorage.getItem('eduType')
      }
      getMajor(param).then(res => {
         if (res) {
            let arr = res.map((item, index) => {
               return {
                  label: item.major,
                  value: item.major
               }
            })
            let colums = []
            colums.push(arr)
            setMajorColums(colums)
         }
      })
   }, [])

   const clickSelect = () => {
      setVisible(true)
   }



   const onUserNameChange = useCallback((value) => {
      //  let target = e.target || e.currentTarget;
      //  console.log(target.value)
      setMajor(value)
   })
   const nextStep = () => {

   
      console.log(">>>>",fileList)
      console.log(">>>>",cardImageList)
      console.log(">>>>",handCardImageList)
      console.log(">>>>",personImageList)
      console.log(">>>>",eduImageList)

      if(!major){
         Toast.show("请选择专业")
         return
      }
      if(fileList.length==0){
         Toast.show("请上传身份证正面照片")
         return
      }
      if(cardImageList.length==0){
         Toast.show("请上传身份证反面照片")
         return
      }
      if(handCardImageList.length==0){
         Toast.show("请上传手持身份证照片")
         return
      }
      if(personImageList.length==0){
         Toast.show("请上传个人照片")
         return
      }
      if(eduImageList.length==0){
         Toast.show("请上传最高学历证书照片")
         return
      }
      let fontList= fileList.map((item,index)=>{return {
         category:1,
         path: item.url
       }})
      let backList= cardImageList.map((item,index)=>{return {
         category:0,
         path: item.url
       }})

    
      
      let param={
         major:major,
         cardImageList:[...fontList,...backList],
         handCardImageList:handCardImageList.map((item,index)=>{return {
            path: item.url
          }}),
         personImageList:personImageList.map((item,index)=>{return {
            path: item.url
          }}),
         eduImageList:eduImageList.map((item,index)=>{return {
            path: item.url
          }})
      }
      
      signStepTwo(param).then(res => {
         if (res) {
            history.push('/pay');
         }
      })

   }
   const beforeUpload = (file) => {
      if (file.size > 1024 * 1024 * 40) {
         Toast.show('请选择小于 40M 的图片')
         return null
      }
      return file
   }

   return <div className="infoFill-container" >


      <NavBar onBack={back}></NavBar>
      <img src={Logo} className="logo"></img>
      <div className="row">
         <img src={OneStepIcon} ></img>
         <img src={TwoStepIcon}></img>
         <img src={ThreeStepIcon} ></img>
      </div>

      <div className="form-box">

         <div className="form-body">
            <div className='row-one'>
               <img src={OneStepFlagIcon}></img>
               <div className="tip">请认真填写以下信息</div>
            </div>

            <div className="item">
               <div className="label">专业：</div>
               <div className="form-item pwd" onClick={clickSelect}>
                  <Input value={major} name="major" placeholder="请选择要报考的专业" readOnly={true} />
                  <img className="eye_icon" src={ArrowIcon}></img>

               </div>

               <div className="label">身份证照片：</div>

               <div className="row-hr">
                  <div className="img-box">
                     <ImageUploader
                        maxCount={1}
                        beforeUpload={beforeUpload}
                        upload={mockUpload}
                        value={fileList} onChange={setFileList}
                     >
                        <img src={CardFontUpload}></img>
                     </ImageUploader>
                  </div>
                  <div className="img-box on">
                     <ImageUploader
                        maxCount={1}
                        beforeUpload={beforeUpload}
                        upload={mockUpload}
                        value={cardImageList}
                        onChange={setCardImageList}
                     >
                        <img src={CardBackUpload}></img>
                     </ImageUploader>
                  </div>
               </div>


               <div className="label">示例：</div>

               <div className="row-hr">
                  <div className="img-box">
                     <img src={CardFont}></img>
                  </div>
                  <div className="img-box on">
                     <img src={CardBack}></img>
                  </div>
               </div>

               <div className="tip">
                  身份证信息保证完整无误，不要有遮挡，露出四个角，最好左右脸的轮廓清楚一点。
               </div>

               <div className="label">手持身份证照片：</div>

               <div className="row-hr">
                  <div className="img-box">
                     <ImageUploader
                        maxCount={1}
                        beforeUpload={beforeUpload}
                        upload={mockUpload}
                        value={handCardImageList}
                        onChange={setHandCardImageList}
                     >
                        <img src={CardIdUpload}></img>
                     </ImageUploader>
                  </div>
                  <div className="img-box on">

                     <img src={CardIdUploadFlag}></img>

                  </div>
               </div>

               <div className="tip">
                  必须是本人手持身份证的照片，在露出身份证的同时注意人脸清晰。
               </div>

               <div className="label">个人照片：</div>

               <div className="row-hr">
                  <div className="img-box">
                     <ImageUploader
                        maxCount={1}
                        beforeUpload={beforeUpload}
                        upload={mockUpload}
                        value={personImageList}
                        onChange={setPersonImageList}
                     >
                        <img src={PersonUpload} className="img-photo"></img>
                     </ImageUploader>
                  </div>
                  <div className="img-box on">

                     <img src={PersonUploadFlag} className="img-photo"></img>

                  </div>
               </div>
               <div className="tip">
                  照片为电子照片，文件大小不能超过40M。
               </div>
               <div className="label">最高学历证书照片：</div>
               <div className="row-hr">
                  <div className="img-box">
                     <ImageUploader
                        maxCount={1}
                        beforeUpload={beforeUpload}
                        upload={mockUpload}
                        value={eduImageList}
                        onChange={setEduImageList}
                     >
                        <img src={EduCertUpload} className="img-cert"></img>
                     </ImageUploader>
                  </div>
                  <div className="img-box on">

                     <img src={EduCertUploadFlag} className="img-cert"></img>

                  </div>
               </div>

               <div className="tip">
                  毕业证照片，保持毕业证照片清晰且大小不超过40M。
               </div>
               <div>
                  <Button className="btn on" onClick={nextStep}>下一步</Button>
               </div>

            </div>

         </div>

      </div>



      <Picker
         columns={majorColums}
         visible={visible}
         onClose={() => {
            setVisible(false)
         }}
         value={value}
         onConfirm={v => {
            console.log(">>>>>", v)

            setMajor(v[0])

         }}
      />

      <Popup
         visible={visible6}
         onMaskClick={() => {
            setVisible6(false)
         }}
      >
         <div
            style={{ height: '600px', overflowY: 'scroll', padding: '20px' }}
         >
            <img src={MajorIcon}></img>
         </div>
      </Popup>
   </div>
}

export default Index