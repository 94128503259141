/*
 * @Author: shao
 * @Date: 2023-08-26 15:33:15
 * @LastEditors: [you name]
 * @LastEditTime: 2023-08-28 14:58:15
 * @Description: 
 */
import { uploadFileApi } from '../../api/index/index';

export async function mockUpload(file) {
  const formData = new FormData();
  formData.append('file', file);
  const data= await uploadFileApi(formData);
  console.log('>>>>',data)
  return {
    url: data.url,
  }
}